import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import TextareaAutosize from "react-textarea-autosize";
// import Markdown from "react-markdown";
// import remarkGfm from "remark-gfm"; // For GitHub Flavored Markdown support
// import rehypeRaw from "rehype-raw"; // For parsing raw HTML
import axios from "axios";
import LogoImg from "./assets/logo_rmbg.png";
import LogoIco from "./assets/favicon.png";
// import { ReactComponent as InfoIcon } from "./assets/icons/info_icon.svg";
import { ReactComponent as ExitFullscreenIcon } from "./assets/icons/fullscreen_exit_icon.svg";
import { ReactComponent as MenuIcon } from "./assets/icons/menu_icon.svg";
import { ReactComponent as MenuClose } from "./assets/icons/menu_close_icon.svg";
import { ReactComponent as SendIcon } from "./assets/icons/send_icon.svg";
import { ReactComponent as CloseIcon } from "./assets/icons/close_icon.svg";
import { ReactComponent as AlertIcon } from "./assets/icons/plan_expired_icon.svg";

function ChatPage() {
  // const [isFullscreen, setIsFullscreen] = useState(false);

  const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth > 1024);
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [sessionId, setSessionId] = useState("");
  const [sessionName, setSessionName] = useState("");
  const [logo, setLogo] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [tag, setTag] = useState("");
  const [chatPairs, setChatPairs] = useState([]);
  const [localQuestions, setLocalQuestions] = useState([]);
  const [localAnswers, setLocalAnswers] = useState([]);
  const [suggestedQuestions, setSuggestedQuestions] = useState([]);
  const [inputQuestion, setInputQuestion] = useState("");
  const [isSendButtonDisabled, setIsSendButtonDisabled] = useState(false);

  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(null);
  const chatPairsRefs = useRef([]);

  const [isContentLoading, setIsContentLoading] = useState(false);
  const [isAnswerLoading, setIsAnswerLoading] = useState(false);

  const chatContainerRef = useRef(null);

  const { search } = useLocation();

  // const requestFullscreen = () => {
  //   // Check if the browser supports Fullscreen API
  //   if (document.fullscreenEnabled) {
  //     // Request fullscreen for the document
  //     document.documentElement
  //       .requestFullscreen()
  //       .then(() => {
  //         setIsFullscreen(true);
  //       })
  //       .catch((error) => {
  //         console.error("Failed to enter fullscreen mode:", error);
  //       });
  //   }
  // };

  // const exitFullscreen = () => {
  //   if (document.fullscreenElement) {
  //     document.exitFullscreen();
  //     setIsFullscreen(false);
  //   }
  // };

  useEffect(() => {
    // Cleanup: Exit fullscreen when component unmounts
    return () => {
      if (document.fullscreenElement) {
        document.exitFullscreen();
      }
    };
  }, []);

  useEffect(() => {
    setSessionId(new URLSearchParams(search).get("session"));
    const chatURL = `https://bot.xplorease.com/?session=${sessionId}`;

    const fetchData = async () => {
      setIsContentLoading(true);
      try {
        // API call for session validation
        await handleSessionValidation(chatURL);

        // API call for fetching suggested questions
        await handleSuggestedQuestions();
      } catch (error) {
        console.error("Error in API calls:", error);
      } finally {
        setIsContentLoading(false);
      }
    };

    if (sessionId) {
      fetchData();
    }
  }, [sessionId]);

  // useEffect(() => {
  //   const fetchSuggestedQuestions = async () => {
  //     setIsContentLoading(true);
  //     try{
  //       // API call for fetching suggested questions
  //       await handleSuggestedQuestions();
  //     } catch (error) {
  //       console.error("Error in API call:", error);
  //     } finally {
  //       setIsContentLoading(false);
  //     }
  //   };

  //   if (sessionId && localQuestions.length === 0) {
  //     // API call for suggested questions
  //     fetchSuggestedQuestions();
  //   }
  // }, [localQuestions]);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const toggleProfile = () => {
    setIsProfileOpen(!isProfileOpen);
  };

  const handleSessionValidation = async (chatURL) => {
    try {
      const response = await axios.post(
        "https://soliboat.solidaridadasia.com/chat_validation",
        {
          url: chatURL,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        const data = response.data;
        console.log(data);
        if (data.success === true) {
          setSessionName(data.data[0].session_name);
          setCompanyName(data.data[0].name);
          setLogo(data.data[0].logo);
          setTag(data.data[0].tag);
        } else {
          console.log("No Data Found!!");
        }
      }
    } catch (error) {
      console.error("Error in Session Validation:", error);
    }
  };

  // const handleFetchPreviousQuestions = async () => {
  //   try {
  //     const response = await axios.post(
  //       "https://soliboat.solidaridadasia.com/previous_questions",
  //       {
  //         session_id: sessionId,
  //       },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );

  //     if (response.status === 200) {
  //       const data = response.data;
  //       if (data.success === true) {
  //         setPreviousQuestions((prevQuestions) => [
  //           ...prevQuestions,
  //           ...data.data[0].questions_list,
  //         ]);
  //         setPreviousAnswers((prevAnswers) => [
  //           ...prevAnswers,
  //           ...data.data[0].answers_list,
  //         ]);
  //       } else {
  //         console.log("No Data Found!!");
  //       }
  //     }
  //   } catch (error) {
  //     console.error("Error in Fetch Previous Questions:", error);
  //   }
  // };

  const handleSuggestedQuestions = async () => {
    try {
      const apiUrl = "https://soliboat.solidaridadasia.com/sample_questions";
      const response = await axios.post(
        apiUrl,
        {
          session_id: sessionId,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        const responseData = response.data;
        if (
          responseData.success &&
          responseData.data &&
          responseData.data.length > 0
        ) {
          const suggestedQuestions = responseData.data[0].answer;
          setSuggestedQuestions(suggestedQuestions);
        } else {
          console.error("Invalid response format:", responseData);
        }
      } else {
        console.error("Error:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("An error occurred during the API request:", error);
    }
  };

  const handleInputChange = (event) => {
    setInputQuestion(event.target.value);
    // setIsInputFocused(true);
  };

  const scrollToQuestion = (index, offset = 48) => {
    
    if (chatPairsRefs[index]) {
  
      const element = chatPairsRefs[index];
      console.log("Element:", element);
  
      const scrollContainer = chatContainerRef.current;
      console.log("Scroll Container:", scrollContainer);
  
      const currentScrollPosition = scrollContainer.scrollTop;
      console.log("Current Scroll Position:", currentScrollPosition);
  
      const targetPosition = element.offsetTop - offset;
      console.log("Target Position:", targetPosition);
  
      const scrollDistance = targetPosition - currentScrollPosition;
      console.log("Scroll Distance:", scrollDistance);
  
      // Scroll to the target position
      scrollContainer.scrollBy({
        top: scrollDistance,
        behavior: "smooth",
      });
  
      if (window.innerWidth < 992) {
        console.log("Window width is less than 992");
        setIsSidebarOpen(false);
      }
  
      setSelectedQuestionIndex(index);
    } else {
      console.log("Element does not exist for index:", index);
    }
  };
  

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      const chatContainer = chatContainerRef.current;

      setTimeout(() => {
        chatContainer.scrollTo({
          top: chatContainer.scrollHeight,
          behavior: "smooth",
        });
      }, 10);
    }
  };

  // Scroll to the bottom after every render
  useEffect(() => {
    scrollToBottom();
  }, [localAnswers.length]);

  const handleSuggestedQuestionAnswer = async (suggestedQuestion) => {
    try {
      setIsAnswerLoading(true);
      setIsSendButtonDisabled(true);
      setLocalQuestions((prevQuestions) => [
        ...prevQuestions,
        suggestedQuestion,
      ]);

      const response = await axios.post(
        "https://soliboat.solidaridadasia.com/answer_question",
        {
          session_id: sessionId,
          question: suggestedQuestion,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        const data = response.data;
        if (data.success === true) {
          setLocalAnswers((prevAnswers) => [
            ...prevAnswers,
            data.data[0].answer,
          ]);
        } else {
          setLocalQuestions((prevQuestions) => prevQuestions.slice(0, -1));
          console.log("No Response!");
        }
      }
    } catch (error) {
      console.error("Error in Question Answer:", error);
    } finally {
      setIsAnswerLoading(false);
      setIsSendButtonDisabled(false);
      scrollToBottom();
    }
  };

  const handleQuestionAnswer = async () => {
    setLocalQuestions((prevQuestions) => [...prevQuestions, inputQuestion]);
    scrollToBottom();
    try {
      // Check Bot Validity
      const botValidityResponse = await axios.post(
        "https://soliboat.solidaridadasia.com/bot_validity",
        {
          url: `https://bot.xplorease.com/?session=${sessionId}`,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (botValidityResponse.status !== 200) {
        setIsDialogOpen(true);
        return;
      }

      setIsAnswerLoading(true);
      setIsSendButtonDisabled(true);

      const response = await axios.post(
        "https://soliboat.solidaridadasia.com/answer_question",
        {
          session_id: sessionId,
          question: inputQuestion,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        const data = response.data;
        if (data.success === true) {
          setLocalAnswers((prevAnswers) => [
            ...prevAnswers,
            data.data[0].answer,
          ]);
          scrollToBottom();
        }
      }
    } catch (error) {
      alert("Something went wrong!");
      setLocalQuestions(localQuestions.slice(0, -1));
      setLocalAnswers(localAnswers.slice(0, -1));
      setChatPairs(chatPairs.slice(0, -1));
      console.error("Error in Question Answer:", error);
    } finally {
      setIsAnswerLoading(false);
      setIsSendButtonDisabled(false);
      setInputQuestion("");
      scrollToBottom();
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent the default behavior of the Enter key
      if (!isSendButtonDisabled && inputQuestion.trim() !== "") {
        handleQuestionAnswer();
        // Clear the input field after sending the question
        setInputQuestion("");
      }
    }
  };

  useEffect(() => {
    setChatPairs(
      localQuestions.map((question, index) => ({
        question,
        answer: localAnswers[index],
        ref: chatPairsRefs[index],
      }))
    );
  }, [localQuestions, localAnswers]);

  //   const answerText = `
  // This is a *regular* paragraph with some text. It can span multiple lines, and the newlines will be preserved.

  // Here's a code block:

  // \`\`\`javascript
  // function greet(name) {
  //   console.log(\`Hello, \${name}!\`);
  // }

  // greet('John');
  // \`\`\`

  // Now, let's look at some lists:

  // - Item 1
  // - Item 2
  //   - Nested item
  // * Item 3

  // # This is a top-level heading

  // Here's another paragraph with more content. The \`formatAnswer\` function should handle inline code snippets like \`console.log('Hello, World!')\` as well.

  // ## This is a second-level heading

  // ### And this is a third-level heading

  // Finally, here's a list with code blocks:

  // - Item 1
  //   \`\`\`
  //   console.log('This is a code block within a list item');
  //   \`\`\`
  // - Item 2
  //   \`\`\`javascript
  //   const greetings = ['Hello', 'Hi', 'Hey'];
  //   greetings.forEach(greeting => console.log(\`\${greeting}, John!\`));
  //   \`\`\`
  // `;

  return (
    <div className="flex h-screen font-nunito bg-dark/10">
      {/* <button onClick={requestFullscreen}>
          {isFullscreen ? "Exit Fullscreen" : "Enter Fullscreen"}
        </button> */}

      {isDialogOpen && (
        <div className="fixed inset-0 flex items-center justify-center drop-shadow-[0_4px_24px_rgba(0,0,0,0.24)] z-50">
          <div className="fixed inset-0 bg-black opacity-50" />

          <div className="relative flex flex-col justify-center items-center p-4 w-[90%] md:w-2/3 lg:w-3/4 xl:w-1/3 bg-white rounded-xl">
            <div className="absolute top-3 right-3 flex rounded-full cursor-pointer hover:scale-[105%]">
              <CloseIcon
                className="w-8 h-8"
                onClick={() => setIsDialogOpen(false)}
              />
            </div>
            <AlertIcon className="w-56 h-56" />
            <div className="flex flex-col justify-center items-center mb-8">
              <h2 className="mt-4 text-gray-900 text-2xl font-semibold">
                Contact Your Administrator
              </h2>
              <p className="mt-1 mx-2 text-center text-sm md:text-base text-gray-400 font-medium">
                The maximum number of tokens allowed for your plan{" "}
                <br className="hidden md:block" /> has been exceeded.
              </p>
            </div>
          </div>
        </div>
      )}

      {/* Sidebar */}
      <div
        className={`absolute md:relative ${
          isSidebarOpen
            ? "w-[90%] md:w-2/3 lg:w-1/4 2xl:w-1/5 transition-width ease-in-out duration-300"
            : "w-0 sm:w-8 transition-width ease-in-out duration-500"
        } bg-dark text-white z-50`}
      >
        {/* NavBar Toggle */}
        <button
          className={`absolute top-3 md:top-10 ${
            isSidebarOpen ? "right-[-30px]" : "right-[-50px]"
          } md:right-[-30px] flex items-center justify-center w-10 h-10 text-black bg-neutral-200 rounded-xl focus:outline-none z-50`}
          onClick={toggleSidebar}
        >
          {!isSidebarOpen ? (
            <MenuIcon className="w-6 h-6" />
          ) : (
            <MenuClose className="w-6 h-6" />
          )}
        </button>

        {/* sidebar content starts here */}
        <div
          className={`flex flex-col justify-between w-full h-screen p-4 ${
            isSidebarOpen ? "" : "hidden"
          }`}
        >
          <div className="w-full flex flex-col overflow-y-scroll no-scrollbar">
            {/* XploreEase Logo & Title */}
            {/*<div className="flex justify-center items-center mt-4 mx-2 p-3 bg-white/80 rounded-xl cursor-pointer">
              <img src={LogoImg} alt="Logo" className="h-8 md:h-10" />
            </div>*/}

            {/* Border */}
            <div className="m-4 border-b border-neutral-400" />
            <h2 className="md:text-lg text-neutral-500 font-semibold ml-4 mb-2">
              Previous Questions:
            </h2>
            <div className="m-4 border-b border-neutral-400" />

            {/* Previous Questions List */}
            <div className="w-full flex flex-col mt-2 overflow-y-scroll no-scrollbar">
              {localQuestions.length === 0 && (
                <p className="font-semibold text-neutral-400 mx-1 p-3">
                  You have not asked any questions yet!
                </p>
              )}
              <ul>
                {localQuestions.map((question, index) => (
                  <li
                    key={index}
                    className="mx-1 mb-1 p-3 hover:bg-neutral-200/20 hover:rounded-xl cursor-pointer"
                    onClick={() => scrollToQuestion(index)}
                  >
                    <p className="text-white line-clamp-3" title={question}>
                      {question}
                    </p>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          {/* Profile Details */}
          <div>
            <div className="m-4 border-b border-neutral-400" />
            <div
              className="min-h-[60px] relative bottom-0 flex items-center mt-4 mx-2 p-3 bg-neutral-200/20 rounded-xl cursor-pointer select-none"
              onClick={toggleProfile}
            >
              <img src={logo} alt="User_Pic" className="w-9 h-9 rounded-full" />
              <h1 className="ml-3 font-semibold">{companyName}</h1>

              {tag && (
                <div
                  className={`${
                    isProfileOpen ? "" : "hidden"
                  } absolute -top-28 -left-4 w-full flex mt-4 ml-4 py-4 px-3 bg-white rounded-xl cursor-pointer`}
                >
                  <img
                    src={logo}
                    alt="User_Pic"
                    className="w-9 h-9 2xl:w-12 2xl:h-12 rounded-full"
                  />
                  <div>
                    <h1 className="ml-2 xl:ml-3 text-sm xl:text-base  text-black font-semibold line-clamp-1">
                      User:
                      <span className="pl-1 font-normal">{companyName}</span>
                    </h1>

                    <h1 className="ml-2 xl:ml-3 text-sm xl:text-base text-black font-semibold line-clamp-1">
                      Tag:<span className="pl-1 font-normal">{tag}</span>
                    </h1>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div
        className={`${
          isSidebarOpen
            ? "lg:w-3/4 xl:w-4/5 transition-width ease-in-out duration-300"
            : "w-full transition-width ease-in-out duration-300"
        } flex flex-col h-screen bg-slate-100`}
      >
        {/* Chat Window */}

        {/* Loading screen while API calls are in progress */}
        {isContentLoading && (
          <div className="fixed inset-0 flex flex-col items-center justify-center bg-neutral-50 z-50">
            <div className="w-20 h-20 animate-spin">
              <div className="h-full w-full border-4 border-t-dark/50 border-b-dark/80 rounded-[50%]" />
            </div>
            <div className="text-sm lg:text-xl font-medium text-gray-500 mt-8 mx-8 lg:mx-0">
              Please wait while the content is loading{" "}
              <span className="text-xl mx-1 inline-block animate-typing">
                .
              </span>
              <span className="text-xl mx-1 inline-block animate-typing">
                .
              </span>
              <span className="text-xl mx-1 inline-block animate-typing">
                .
              </span>
            </div>
          </div>
        )}

        {/* Full-screen button */}
        {/* {isFullscreen === false && (
          <div className="md:hidden fixed inset-0 flex flex-col items-center justify-center bg-neutral-50 z-50">
            <InfoIcon
              className="w-6 h-6 mb-2 cursor-pointer"
              fill="none"
              stroke="#6495ED"
            />
            <p className="w-[80%] text-center mb-4">
              This app is best viewed in fullscreen mode, please enable
              fullscreen by clicking the button below.
            </p>
            <button
              className="h-10 bg-dark text-white py-2 px-3 rounded-md hover:bg-blue-800 focus:outline-none focus:shadow-outline-blue active:bg-blue-800"
              onClick={requestFullscreen}
            >
              {isFullscreen ? "Exit Fullscreen" : "Enable Fullscreen"}
            </button>
          </div>
        )} */}

        {localQuestions.length === 0 ? (
          <div
            className={`${
              isSidebarOpen ? "hidden lg:flex" : ""
            } flex flex-col items-center justify-center w-full`}
            style={{ height: "calc(100% - 102px)" }}
          >
            {/* Exit Fullscreen Button */}
            {/* <div className="md:hidden  absolute top-3 right-3 flex rounded-full cursor-pointer hover:scale-[105%]">
              <button
                className="flex items-center bg-white text-black py-2 px-2 rounded-md focus:outline-none"
                onClick={exitFullscreen}
              >
                <ExitFullscreenIcon
                  className="w-5 h-5 mr-2"
                  onClick={() => setIsDialogOpen(false)}
                />{" "}
                <span className="text-sm">
                  {isFullscreen ? "Exit Fullscreen" : "Enable Fullscreen"}
                </span>
              </button>
            </div> */}

            <div className="flex flex-col items-center justify-end h-3/5 xs:h-1/2 mb-4">
              <img
                src={logo}
                alt="Logo"
                className="w-24 h-24 lg:w-28 lg:h-28 p-1 mb-4 md:mb-0 bg-white rounded-full"
              />
              <h1 className="mt-4 lg:mt-6 mb-6 text-center text-lg md:text-xl lg:text-2xl font-semibold">
                <p>Welcome to {sessionName}!</p>
                <p className="text-lg md:text-xl lg:text-2xl font-normal">
                  What can I help you with?
                </p>
              </h1>
            </div>
            {/* Suggested Questions */}
            <div
              className={`${
                isSidebarOpen ? "w-3/4" : "w-[90%] md:w-4/5 xl:w-3/5"
              } h-2/5 xs:h-1/2 flex align-bottom`}
            >
              <div className="flex items-end mb-4">
                <div className="grid grid-cols-2 gap-1 sm:gap-1.5">
                  {suggestedQuestions.map((question, index) => (
                    <div
                      key={index}
                      className="mx-1 mb-1 p-2 xs:p-3 shadow rounded-lg sm:rounded-xl bg-white/20 hover:bg-dark/10 cursor-pointer"
                      onClick={() => {
                        handleSuggestedQuestionAnswer(question.substring(3));
                      }}
                    >
                      <p className="text-[10px] xs:text-xs sm:text-sm md:text-base text-black md:leading-snug line-clamp-3">
                        {question}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div
            ref={chatContainerRef}
            className={`${
              isSidebarOpen ? "hidden lg:flex" : ""
            } relative flex flex-col items-center w-full overflow-y-scroll no-scrollbar`}
            style={{ height: "calc(100% - 84px)" }}
          >
            <div className="fixed top-0 w-full h-[40px] bg-white/90 z-40">
              <div className="fixed right-3 flex items-center justify-end rounded-full cursor-pointer">
                <a
                  href="https://xplorease.com/"
                  className="h-10 flex items-center text-black py-2 px-2 rounded-md focus:outline-none"
                  // onClick={exitFullscreen}
                >
                  <ExitFullscreenIcon
                    className="w-5 h-5 mr-2"
                    onClick={() => setIsDialogOpen(false)}
                  />{" "}
                  <span className="text-sm">Close Session</span>
                </a>
              </div>
            </div>
            <div className="w-[90%] md:w-4/5 mt-12 mb-6 lg:mb-0">
              {chatPairs.map((pair, index) => (
                <div key={index} className="w-full">
                  <div
                    className="relative w-full md:w-4/5 lg:2/3 flex justify-end md:justify-start md:bg-[#1F4172]/90 rounded-r-xl rounded-b-xl"
                    ref={(ref) => (chatPairsRefs[index] = ref)}
                  >
                    <img
                      src={logo}
                      alt="User_Pic"
                      className="absolute right-0 bottom-0 md:static w-6 h-6 md:min-w-[32px] md:w-8 md:h-8 lg:min-w-[40px] lg:w-10 lg:h-10 md:ml-2 md:my-3 md:p-1 bg-white rounded-full"
                    />
                    <p
                      className="float-right mr-8 md:mr-0 font-nunito text-sm md:text-base bg-[#1F4172]/90 md:bg-[#1F4172]/0 text-white p-3 md:p-4 rounded-xl"
                      style={{
                        width: "calc(100% - 32px)",
                        wordWrap: "break-word",
                      }}
                    >
                      {pair.question}
                    </p>
                  </div>
                  <div className="relative w-full md:w-4/5 lg:2/3 float-right flex md:justify-between mt-3 mb-6 md:bg-white md:rounded-tr-none rounded-xl">
                    <img
                      src={LogoIco}
                      alt="Favicon"
                      className="md:hidden absolute bottom-0 w-6 h-6 md:p-1 bg-neutral-100 rounded-full"
                    />
                    <p
                      className="ml-8 md:ml-0 font-nunito text-sm md:text-base bg-white text-black p-3 md:p-4 rounded-xl"
                      style={{
                        width: "calc(100% - 40px)",
                      }}
                    >
                      {/* Typing animation */}
                      {index === chatPairs.length - 1 && isAnswerLoading ? (
                        <span className="text-gray-500 mt-2">
                          Typing
                          <span className="text-xl mx-1 inline-block animate-typing">
                            .
                          </span>
                          <span className="text-xl mx-1 inline-block animate-typing">
                            .
                          </span>
                          <span className="text-xl mx-1 inline-block animate-typing">
                            .
                          </span>
                        </span>
                      ) : (
                        <>{<div dangerouslySetInnerHTML={{ __html: pair.answer }} />}</>
                      )}
                    </p>
                    <img
                      src={LogoIco}
                      alt="Favicon"
                      className="hidden md:block w-8 lg:w-10 h-8 lg:h-10 mr-2 my-3 md:p-1 bg-neutral-100 rounded-full"
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {/* Input */}
        <div
          className={`${
            window.innerWidth < 1024 ? "fixed bottom-0 left-0" : ""
          } flex flex-col justify-center items-center w-full pt-4 bg-[#1320431a]`}
        >
          <div
            className={`${
              isSidebarOpen
                ? "hidden lg:flex w-3/4"
                : "w-[90%] md:w-4/5 xl:w-3/5"
            } overflow-hidden flex items-center justify-center border rounded-3xl bg-white shadow-[0_0_0_2px_rgba(255,255,255,0.95)] focus:shadow-[0_2px_6px_rgba(0,0,0,.05)]`}
          >
            <TextareaAutosize
              minRows={1}
              value={inputQuestion}
              onChange={handleInputChange}
              // onFocus={() => setIsInputFocused(true)}
              // onBlur={() => setIsInputFocused(false)}
              onKeyDown={handleKeyDown}
              maxRows={5}
              placeholder="Type your question here"
              className="w-full py-3.5 pr-4 md:pr-12 pl-4 text-sm xs:text-base resize-none rounded-lg focus:outline-none placeholder:max-w-fit placeholder:text-sm xs:placeholder:text-base placeholder:line-clamp-1 placeholder:pt-[2px] xs:placeholder:pt-0 no-scrollbar"
            />
            <button
              className="w-8 h-8 p-1 mr-4 border border-black rounded-xl hover:scale-[102%] hover:shadow-one hover:bg-black/10 disabled:cursor-not-allowed disabled:scale-100 disabled:shadow-none"
              onClick={() => handleQuestionAnswer()}
              disabled={isSendButtonDisabled || inputQuestion === ""}
            >
              <SendIcon className="w-6 h-6" fill="white" />
            </button>
          </div>
          <p
            className={`${
              isSidebarOpen && window.innerWidth < 768
                ? "hidden"
                : "flex items-center"
            } my-2 text-sm text-gray-500`}
          >
            Powered by{" "}
            <a
              href="https://xplorease.com/"
              className="flex items-center ml-1 text-blue-700 font-medium cursor-pointer"
            >
              <img src={LogoImg} alt="🤖" className="h-4" />
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default ChatPage;
